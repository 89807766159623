



















  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    name: 'GeneralLayout',
    components: {
      NavBar: () => import('@/components/layouts/NavBar.vue'),
      FooterBar: () => import('@/components/layouts/Footer.vue'),
    },
    mixins: [],
  })
  export default class GeneralLayout extends Vue {
    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
